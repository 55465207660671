<template>
  <div>
    <v-card>
      <v-card-title>All Reviews</v-card-title>
      <v-toolbar dense flat outlined>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed>
              Actions
              <v-icon right dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="action in actions" :key="action.title" link>
              <v-list-item-title @click="action.hander()">
                {{ action.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-text-field
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
          placeholder="Search..."
        ></v-text-field>
      </v-toolbar>
      <ReviewTable :search="search" :type="'product'" :productId="productId" v-on:inputChange="updateSelected" />
    </v-card>
  </div>
</template>

<script>
import ReviewTable from './components/ReviewTable.vue';

export default {
  components: {
    ReviewTable,
  },
  data() {
    let query = this.$route.query;
    return {
      productId: query.product_id,
      search: '',
      actions: [
        {
          title: 'Delete',
          hander: this.deleteReviews,
        },
        // {
        //   title: 'Publish',
        //   hander: this.publish,
        // },
        // {
        //   title: 'Unpublish',
        //   hander: this.unpublish,
        // },
      ],
      selected: [],
    };
  },
  methods: {
    async deleteReviews() {
      if (!this.selected.length) return;
      this.$store
        .dispatch('deleteProductReviews', {
          ids: this.selected.join(','),
          productId: this.productId,
          type: 'product',
        })
        .then(() => {
          console.log('deleted');
        })
        .catch(error => {
          console.log(error);
        });
    },
    publish() {
      console.log('publish');
    },
    unpublish() {
      console.log('unpublish');
    },
    updateSelected(selected) {
      this.selected = selected;
    },
  },
  watch: {
    selected: function(old, current) {
      console.log(old, current);
    },
  },
};
</script>

<style></style>
